
import PlanTag from '@/components/helper/PlanTag.vue'
import PromptYesNo from '@/components/modals/PromptYesNo.vue'
import StripeRedirect from '@/components/modals/StripeRedirect.vue'
import { defaultInterval, plans } from '@/data/plansData.json'
import SubscriptionTracking from '@/mixin/SubscriptionTracking'
import {
  CreateCheckoutRequest,
  CreateCustomerPortalRequest,
  PlanAction,
  PlanItem,
  PlanPrice,
} from '@/modules/subscription/types'
import { Permissions } from '@/security/permissions'
import { Tenant } from '@/store/modules/app/types'
import { AppConst } from '@/store/modules/constants'
import { DynaconfConfig, Namespace, PermissionActions } from '@/store/types'
import { AppHelper } from '@/utils/app-helper'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

declare var consoleLog: any

@Component({
  components: {
    'prompt-yes-no': PromptYesNo,
    'stripe-redirect': StripeRedirect,
    'plan-tag': PlanTag,
  },
})

export default class SubscriptionVue extends Mixins(SubscriptionTracking) {
  @State('loading', { namespace: Namespace.Utils }) public loading!: boolean
  @State('checkoutUrl', { namespace: Namespace.App }) public checkoutUrl!: string
  @State('customerPortalUrl', { namespace: Namespace.App }) public customerPortalUrl!: string
  @State('dynaconfConfig', { namespace: Namespace.App }) public dynaconfConfig!: DynaconfConfig

  @Action('createCheckout', { namespace: Namespace.App }) public createCheckout: any
  @Action('createCustomerPortal', { namespace: Namespace.App }) public createCustomerPortal: any
  @Action('getDynaconfConfig', { namespace: Namespace.App }) public getDynaconfConfig: any
  @Action('loadMyTenant', { namespace: Namespace.App }) public loadMyTenant: any

  private superUser: boolean = false
  public isPreparingPage: boolean = true
  public planItems: any = plans
  public defaultInterval: string = defaultInterval
  public isRequesting: boolean = false
  public myTenantNotReadyToLoadOnMount: boolean = false
  public isMonthlyIntervalSelected: boolean = true

  public $refs!: Vue['$refs'] & {
    checkoutResultPromptSuccess: PromptYesNo
    checkoutResultPromptCancel: PromptYesNo
    cancelSubscriptionPromptYesNo: PromptYesNo
    redirectToStripeCheckout: StripeRedirect
  }

  constructor() {
    super()
    const tenantTokens = this.$auth0.tenants
    this.superUser = TenantHelpers.IsSuperUser(tenantTokens)

    // In the future, consider getting plan data for pricesUSD from the latest pricing set in api config available at api.jig.space/configs/v1
    // instead of a local json file.
    this.isMonthlyIntervalSelected = defaultInterval === 'month'
    var lookingForInterval = this.isMonthlyIntervalSelected ? 'month' : 'year'
    for (const planItem of this.planItems) {
      planItem.selectedPrice = planItem.prices[0]
      for (const price of planItem.prices) {
        if (price.interval === lookingForInterval) {
          planItem.selectedPrice = price
          break
        }
      }
    }
  }

  protected async mounted() {
    consoleLog(`Subscription mounted() hit`)
    if (this.myTenant.ID !== TenantHelpers.InvalidTenantID && this.myTenant && this.myTenant.Subscription) {
      await this.preparePage()
    }

    if (this.$route.query.checkoutresult === 'success') {
      await this.subscriptionEventTracking({
        gtmEventData: {
          action: 'CheckoutSuccess',
          category: 'CheckoutSelect',
          label: 'CheckoutSuccess',
        },
        segmentEventName: 'CheckoutSuccess'
      })

      this.$refs.checkoutResultPromptSuccess.ShowPrompt()
      if (this.myTenant.ID === TenantHelpers.InvalidTenantID) {
        consoleLog(`Subscription mounted() but myTenantNotReadyToLoadOnMount`)
        // this mounted before the tenant finished loading so instead of calling load now we'll call in the watch() once
        this.myTenantNotReadyToLoadOnMount = true
      } else {
        this.isRequesting = true
        consoleLog(`Subscription mounted() loadMyTenant ${this.myTenant.ID} start`)
        await this.loadMyTenant({ TenantId: this.myTenant.ID, clearCache: true })
        consoleLog(`Subscription mounted() loadMyTenant ${this.myTenant.ID} finish`)
        this.isRequesting = false
      }
    } else if (this.$route.query.checkoutresult === 'cancel') {
      await this.subscriptionEventTracking({ segmentEventName: 'CheckoutCancelled' })

      this.$refs.checkoutResultPromptCancel.ShowPrompt()
    } else {
      await this.subscriptionEventTracking({ gtmView: 'Pricing', segmentEventName: 'CheckoutSelection' })
    }
  }

  @Watch('myTenant')
  public async onMyTenantChanged(value: Tenant) {
    consoleLog(`Subscription onMyTenantChanged() hit`)
    if (this.myTenant.ID !== TenantHelpers.InvalidTenantID && this.myTenant && this.myTenant.Subscription) {
      await this.preparePage()

      if (this.myTenantNotReadyToLoadOnMount) {
        this.myTenantNotReadyToLoadOnMount = false
        this.isRequesting = true
        consoleLog(`Subscription onMyTenantChanged() loadMyTenant ${this.myTenant.ID} start`)
        await this.loadMyTenant({ TenantId: this.myTenant.ID, clearCache: true })
        consoleLog(`Subscription onMyTenantChanged() loadMyTenant ${this.myTenant.ID} finish`)
        this.isRequesting = false
      }
    } else {
      if (this.myTenantNotReadyToLoadOnMount) {
        consoleLog(`Subscription onMyTenantChanged() and myTenantNotReadyToLoadOnMount but the this.myTenant.ID is invalid or doesn't have a subscription`)
      }
    }
  }

  @Watch('isMonthlyIntervalSelected')
  public onIsMonthlyIntervalSelected(isMonthlyIntervalSelected: boolean) {
    var lookingForInterval = isMonthlyIntervalSelected ? 'month' : 'year'
    for (const planItem of this.planItems) {
      let newSelectedPrice = {} as PlanPrice
      for (const price of planItem.prices) {
        if (price.interval === lookingForInterval) {
          newSelectedPrice = price
          break
        }
      }
      // if there's no matching selected price with the interval then leave the last one selected
      if (newSelectedPrice.displayPrice) {
        planItem.selectedPrice = newSelectedPrice
      }
    }
  }

  private get tierTagCopy(): string {
    return this.currentTier === AppConst.Subscription.subscriptionTierTeam ? 'Recommended' : 'Most popular'
  }

  private get isShowingSubscriptionDetails(): boolean {
    return !this.loading && !this.isPreparingPage && this.isManagedByTeamManager
  }

  private get isClassicPricingSet(): boolean {
    return this.myTenant.Subscription && this.dynaconfConfig.pricing.sets[this.myTenant.Subscription.PricingSet] == null
  }

  private get currentTier(): string {
    return this.myTenant.Subscription ? this.myTenant.Subscription.TierType : ''
  }

  private get currentTierIndex() {
    return this.planItems.findIndex((plan: PlanItem) => plan.tier === this.currentTier)
  }

  private get openJigSpace() {
    return AppHelper.openJigSpaceUrl()
  }

  private get isUserTeamManager(): boolean {
    if (this.myTenant.ID === TenantHelpers.InvalidTenantID) {
      return false
    }

    const tenantTokens = this.$auth0.tenants
    const tenantToken = TenantHelpers.GetTenantTokenByTenantID(
      tenantTokens,
      this.myTenant.ID
    )

    return (tenantToken !== undefined &&
      Permissions.TokenHasPermissionAction(
        tenantToken,
        Permissions.PermTenant,
        PermissionActions.Manage
      ))
  }

  private get checkoutPayload(): any {
    const successPath = this.$route.query.stripeSuccess ? this.$route.query.stripeSuccess : this.$route.path
    const cancelPath = this.$route.query.stripeCancel ? this.$route.query.stripeCancel : this.$route.path
    const sucessCallbackURLBase = `${window.location.origin}${successPath}?checkoutresult=`
    const cancelCallbackURLBase = `${window.location.origin}${cancelPath}?checkoutresult=`
    const successUrl = `${sucessCallbackURLBase}success`
    const cancelUrl = `${cancelCallbackURLBase}cancel`
    const emailValue = this.$auth0.user.email

    return {
      tenantId: this.myTenant.ID,
      email: emailValue,
      successUrl,
      cancelUrl,
    }
  }

  private get canCurrentTierManageDeleteSubscription(): boolean {
    // Only allow manage subscription if they're a team manager and not on the free tier (free tiers aren't set up in Stripe)
    return this.currentTier === AppConst.Subscription.subscriptionTierTeam || this.currentTier === AppConst.Subscription.subscriptionTierOrganization
  }

  private get isManagedByTeamManager(): boolean {
    return this.myTenant.Subscription && this.myTenant.Subscription.ManagedByType === AppConst.Subscription.ManagedByTypeTeamManager
  }

  private get isManagedByJigStaff(): boolean {
    return this.myTenant.Subscription && this.myTenant.Subscription.ManagedByType === AppConst.Subscription.ManagedByTypeJigStaff
  }

  private get intervalLabel(): string {
    return this.isMonthlyIntervalSelected ? 'Monthly' : 'Yearly (30% discount)'
  }

  private async trackCTAClick(tier: string, ctaText: string) {
    let segmentEventName = 'CheckoutSelectPlanButtonClicked'
    let gtmEventName = ''
    const isDowngrading = /(cancel)|(downgrade)/gi.test(ctaText)

    switch (tier) {
      case 'team':
        if (isDowngrading) {
          segmentEventName = 'CheckoutDowngradeClicked'
        } else {
          gtmEventName = 'CheckoutSelectTeam'
        }
        break
      case 'organization':
        if (isDowngrading) {
          segmentEventName = 'CheckoutDowngradeClicked'
        } else {
          gtmEventName = 'CheckoutSelectTeamOrganization'
        }
        break
      default:
        return
    }

    await this.subscriptionEventTracking({
      segmentEventName,
      segmentEventExtraData: {
        Subscription: AppHelper.getTierType(tier),
      }
    })

    if (!isDowngrading) {
      this.$gtm.trackEvent({
        action: gtmEventName,
        category: 'CheckoutSelect',
        label: ctaText,
        value: tier,
      })
    }
  }

  private async preparePage() {
    // If tenant is on old pricing set, redirect user to Team branding page to check Subscription details
    // This usually shouldn't happen as old pricing set users won't see this page link in Drawer.
    // It might happen when user stays on Subscription page of a tenant and then switched to a tenant that has no Subscription page access.
    // Or if a user from the unity app is shown a feature gate Upgrade button which wants to send them to https://dashboard.jig.space/subscription - but the tenant is on the old pricing set (rarely happen)

    // Sometimes on redirecting back from stripe
    // global data initialising is not yet finished in Drawer,
    // classic pricing check computed property will return
    // `true` since dynaconfConfig store is still empty.
    // To avoid this issue, before redirect condition check,
    // we retrieve dyaconf values if it's empty.
    if (Object.keys(this.dynaconfConfig.pricing.sets).length === 0) {
      await this.getDynaconfConfig()
    }

    if (this.isClassicPricingSet) {
      this.$router.push({ path: '/tenant' })
    }
    this.isRequesting = false

    this.isPreparingPage = false
  }

  public getPlanAction(index: number, plan: PlanItem): PlanAction {
    var planAction
    if (index > this.currentTierIndex) {
      planAction = plan.actions.find((a) => a.tierCondition === 'currentTierIsLowerPlan')
    } else if (index < this.currentTierIndex) {
      planAction = plan.actions.find((a) => a.tierCondition === 'currentTierIsHigherPlan')
    } else if (plan.tier === this.currentTier) {
      planAction = plan.actions.find((a) => a.tierCondition === 'currentTierEqualsPlan')
    }
    if (!planAction) {
      consoleLog(`Could not find appropriate plan action`)
      return {} as PlanAction
    } else {
      return planAction
    }
  }

  public getActionUrlAsAttributes(index: number, plan: PlanItem) {
    const attributes: any = {}
    var action = this.getPlanAction(index, plan)
    if (action.isCreatingCheckout) {
      return // this is handled in the planCtaClick
    }
    attributes.href = action.buttonUrl
    attributes.target = '_blank'

    if (!this.isUserTeamManager) {
      attributes.disabled = true
    }

    return attributes
  }

  public async planCtaClick(index: number, plan: PlanItem, event: Event) {
    var action = this.getPlanAction(index, plan)
    if (!this.isUserTeamManager) {
      event.preventDefault()
      return // return if CTA button is disabled
    }

    // Even it's link click (downgrade, cancel), we will need to track click event.
    await this.trackCTAClick(plan.tier, action.buttonLabel)

    if (!action.isCreatingCheckout) {
      return // this is handled in the v-bind which will use turns the buttonUrl into an href
    }

    this.isRequesting = true
    const createCheckoutRequest: CreateCheckoutRequest = {
      UrlParams: {
        ...this.checkoutPayload,
        interval: plan.selectedPrice.interval,
        // consider passing latest pricingSet from the api config available at api.jig.space/configs/v1
      }
    }
    this.$refs.redirectToStripeCheckout.ShowPrompt()
    if (await this.createCheckout(createCheckoutRequest)) {
      if (this.checkoutUrl) {
        window.location.assign(this.checkoutUrl)
      } else {
        this.$refs.redirectToStripeCheckout.HidePrompt()
        this.isRequesting = false
      }
    } else {
      // Errors from the api response are already caught in the generic api error modal
      this.$refs.redirectToStripeCheckout.HidePrompt()
      this.isRequesting = false
    }
  }

  public async createCustomerPortalClick() {
    if (!this.isUserTeamManager) {
      return
    }

    this.isRequesting = true
    const createCustomerPortalRequest: CreateCustomerPortalRequest = {
      UrlParams: {
        // If you need to test returnUrl on localhost, you must set https: true in the vue.config.js devServer
        // because Stripe API will only accept a returnUrl starting with https.
        returnUrl: `${window.location.origin}${this.$route.path}`
      }
    }
    this.isRequesting = true
    this.$refs.redirectToStripeCheckout.ShowPrompt()
    if (await this.createCustomerPortal(createCustomerPortalRequest)) {
      if (this.customerPortalUrl) {
        window.location.assign(this.customerPortalUrl)
      } else {
        this.$refs.redirectToStripeCheckout.HidePrompt()
        this.isRequesting = false
      }
    } else {
      // Errors from the api response are already caught in the generic api error modal
      this.$refs.redirectToStripeCheckout.HidePrompt()
      this.isRequesting = false
    }
  }

  // If current tier is Organization, do not show `downgrade` cta
  public isShowingSubscriptionCTA(tier: string) {
    return !(tier === AppConst.Subscription.subscriptionTierOrganization && this.myTenant.Subscription && this.myTenant.Subscription.TierType === AppConst.Subscription.subscriptionTierOrganization)
  }
}
